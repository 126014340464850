







































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      form: {
        username: '',
        file: null,
      },
      imageSrc: '',
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMessage',
    }),
  },
  methods: {
    updateFile(event: any) {
      const file = event.target.files[0];
      if (file) {
        this.imageSrc = URL.createObjectURL(file);
        this.form.file = file;
      }
    },
    submitForm() {
      const form = this.form;
      this.$store.dispatch('auth/updateUserProfile', form);
    },
  },
});
